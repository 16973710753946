import { Button, Checkbox, Flex, Input, message, Modal, Select } from "antd";
import { useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Problem } from "../model/ProblemCategoryModel";
import ProblemCategoryApi from "../apis/problemCategoryApi";

// 问题选项使用
let optionId = 0;

const NewProblemCategory: React.FC<{
  isModel: boolean;
  setIsModel: (isModel: boolean) => void;
  onSubmit: (problem: Problem) => void;
  initProblem: Problem;
}> = ({ isModel, setIsModel, onSubmit, initProblem }) => {
  // const [messageApi, contextHolder] = message.useMessage();
  const [problem, setProblem] = useState<Problem>({ ...initProblem });

  return (
    <Modal
      open={isModel}
      onOk={() => {
        if (
          problem.question === "" ||
          (problem.type !== 2 &&
            problem.options.every((item) => item.questionOption === ""))
        ) {
          message.error("您还有必填项未填写!");
          return;
        }

        if(problem.limitMaxAge && problem.limitMinAge && problem.limitMinAge > problem.limitMaxAge) {
          message.error("年龄下限不得大于年龄上限!");
          return;
        }

        message.loading({
          duration: 0,
          content: "",
          key: "SetProblemCategoryLoading",
        });
        ProblemCategoryApi.setProblem(problem).then((value) => {
          problem.questionId = value.data["questionId"];
          onSubmit(problem);
          setIsModel(false);
          message.destroy("SetProblemCategoryLoading");
          message.success(
            `${problem.questionId !== "" ? "修改" : "新增"}问题成功`
          );
        });
      }}
      onCancel={() => setIsModel(false)}
    >
      <h4>{problem.questionId !== "" ? "修改" : "新增"}问题</h4>
      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div className="medalBefore" style={{ width: 100, textAlign: "right" }}>
          问题：
        </div>
        <Input
          style={{ flex: 1 }}
          placeholder="请输入问题"
          defaultValue={problem.question}
          allowClear
          onChange={(e) => {
            const newProblem = { ...problem };
            newProblem.question = e.target.value;
            setProblem({ ...newProblem });
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div className="medalBefore" style={{ width: 100, textAlign: "right" }}>
          问题类型：
        </div>
        <Select
          style={{ flex: 1 }}
          placeholder="请选择问题类型"
          value={problem.type}
          onSelect={(v) => {
            const newProblem = { ...problem };
            newProblem.type = v;

            if (v === 3 && newProblem.options.length > 3) {
              newProblem.options.length = 3;
            } else if (v === 1 && newProblem.options.length < 3) {
              const length = newProblem.options.length;
              for (let index = 0; index < 3 - length; index++) {
                newProblem.options.push({
                  id: ++optionId,
                  questionOption: "",
                  noneStatus: false,
                  otherStatus: false,
                  index: 0,
                });
              }
            }
            setProblem({ ...newProblem });
          }}
          options={[
            {
              label: "单选",
              value: 0,
            },
            {
              label: "多选",
              value: 1,
            },
            {
              label: "文本",
              value: 2,
            },
            {
              label: "图片",
              value: 3,
            },
          ]}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div style={{ width: 100, textAlign: "right" }}>问题描述：</div>
        <Input
          style={{ flex: 1 }}
          placeholder="请输入问题描述"
          defaultValue={problem.describe}
          allowClear
          onChange={(e) => {
            problem.describe = e.target.value;
          }}
        />
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <div style={{ width: 100, textAlign: "right" }}>年龄上限：</div>
          <Input
            style={{ flex: 1 }}
            defaultValue={problem.limitMaxAge}
            min={0}
            type="number"
            onChange={(e) => {
              problem.limitMaxAge = e.target.value ? parseInt(e.target.value) : undefined;
            }}
          />
        </div>


        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <div style={{ width: 100, textAlign: "right" }}>年龄下限：</div>
          <Input
            style={{ flex: 1 }}
            defaultValue={problem.limitMinAge}
            min={0}
            type="number"
            onChange={(e) => {
              problem.limitMinAge = e.target.value ? parseInt(e.target.value) : undefined;
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
        <div className="medalBefore" style={{ width: 100, textAlign: "right" }}>
          是否必填：
        </div>
        <Select
          style={{ flex: 1 }}
          defaultValue={problem.required}
          onSelect={(v) => {
            const newProblem = { ...problem };
            newProblem.required = v;
            setProblem({ ...newProblem });
          }}
          options={[
            {
              label: "否",
              value: false,
            },
            {
              label: "是",
              value: true,
            },
          ]}
        />
      </div>

      {problem.type !== 2 &&
        problem.options &&
        problem.options.map((item, key) => (
          <div key={item.id} style={{ marginTop: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="medalBefore"
                style={{ width: 100, textAlign: "right" }}
              >
                选项{key + 1}：
              </div>
              <Input
                style={{ flex: 1 }}
                placeholder="请输入选项值"
                defaultValue={item.questionOption}
                suffix={
                  ((problem.type === 1 && problem.options.length > 3) ||
                    (problem.type !== 1 && problem.options.length > 1)) && (
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => {
                        if (problem.options.length <= 1) return;
                        const newCategoryValueList = problem.options.filter(
                          (lastItem, number) => item.id !== lastItem.id
                        );
                        problem.options = [...newCategoryValueList];
                        setProblem({ ...problem });
                      }}
                    />
                  )
                }
                onChange={(e) =>
                  (problem.options[key].questionOption = e.target.value)
                }
              />
            </div>

            {problem.type !== 3 && (
              <div style={{ paddingLeft: 100, marginTop: 5 }}>
                <Checkbox
                  defaultChecked={item.noneStatus}
                  onChange={(e) => (item.noneStatus = e.target.checked)}
                >
                  无
                </Checkbox>
                <Checkbox
                  defaultChecked={item.otherStatus}
                  onChange={(e) => (item.otherStatus = e.target.checked)}
                >
                  其他
                </Checkbox>
              </div>
            )}
          </div>
        ))}

      {((problem.type !== 3 &&
        problem.type !== 2 &&
        problem.options.length < 16) ||
        (problem.type === 3 && problem.options.length < 3)) && (
        <Flex style={{ marginTop: 5 }}>
          <div style={{ width: 80, height: 1 }} />

          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => {
              problem.options.push({
                id: ++optionId,
                questionOption: "",
                noneStatus: false,
                otherStatus: false,
                index: 0,
              });
              setProblem({ ...problem });
            }}
          >
            添加新选项
          </Button>
        </Flex>
      )}

      {problem.type === 1 && (
        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            最多可选：
          </div>
          <Input
            style={{ flex: 1 }}
            addonAfter={
              problem.limitOptionsNumber + "/" + problem.options.length
            }
            placeholder="请输入问题描述"
            type="number"
            defaultValue={problem.limitOptionsNumber}
            min={1}
            max={problem.options.length}
            onChange={(e) => {
              const newProblem = { ...problem };
              newProblem.limitOptionsNumber = parseInt(e.target.value);
              setProblem({ ...newProblem });
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default NewProblemCategory;

import { Problem } from "../model/ProblemCategoryModel";
import request from "./request";

export default class ProblemCategoryApi {
  /**
   * 问题列表
   */
  public static getProblemList = (
    pageNumber: number,
    name: string,
    type?: number
  ) => {
    return request({
      url: `/api/v1/question/paged`,
      method: "GET",
      params: {
        name: name,
        type: type,
        pageNumber: pageNumber,
        pageSize: 10,
      },
    });
  };

  /**
   * 全部问题列表
   */
  public static getProblemTemplateList = () => {
    return request({
      url: `/api/v1/question/template`,
      method: "GET"
    });
  };

  /**
   * 获取问题详情
   */
  public static getProblemDetails = (questionId: string) => {
    return request({
      url: `/api/v1/question/info`,
      method: "GET",
      params: { questionId: questionId },
    });
  };

  /**
   * 新增/修改问题
   */
  public static setProblem = (problem: Problem) => {
    return request({
      url: `/api/v1/question`,
      method: "POST",
      data: problem,
    });
  };

  /**
   * 删除问题
   */
  public static deleteProblem = (questionId: string) => {
    return request({
      url: `/api/v1/question/${questionId}`,
      method: "DELETE",
    });
  };
}

import {
  Button,
  DatePicker,
  Image,
  Input,
  Radio,
  Select,
  Table,
  TableProps,
} from "antd";
import "./consultation_record_style.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConsultationRecordApi from "../../apis/consultationRecordApi";
import moment from "moment";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface DataType {
  recordId: string;
  avatar: string;
  nickName: string;
  createTime: string;
  name: string;
  gender: "man" | "women";
  age: number;
  symptomDescription: string;
  state: number;
  doctorName: string;
}

const ConsultationRecord: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataType[]>([]);
  // 诊断状态
  const [state, setState] = useState<number>();
  // 医生名称
  const [doctorName, setDoctorName] = useState<string>('');
  // 患者名称
  const [patientName, setPatientName] = useState<string>('');
  // 开始日期
  const [startTime, setStartTime] = useState<string | null>(null);
  // 结束日期
  const [endTime, setEndTime] = useState<string | null>(null);
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      align: "center" as const,
      render: (_, record) => (
        <Image width={50} height={50} src={record.avatar} />
      ),
    },
    {
      title: "昵称",
      dataIndex: "nickName",
      key: "nickName",
      align: "center" as const,
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
    },
    {
      title: "性别",
      dataIndex: "gender",
      key: "gender",
      align: "center" as const,
      render: (_, record) => (
        <span>{record.gender === "man" ? "男" : "女"}</span>
      ),
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
      align: "center" as const,
    },
    {
      title: "主诉",
      dataIndex: "symptomDescription",
      key: "symptomDescription",
      align: "center" as const,
      width: 500
    },
    {
      title: "填写日期",
      dataIndex: "createTime",
      key: "createTime",
      align: "center" as const,
    },
    {
      title: "诊断状态",
      dataIndex: "state",
      key: "state",
      align: "center" as const,
      render: (_, record) => returnState(record.state),
    },
    {
      title: "诊断医生",
      dataIndex: "doctorName",
      key: "doctorName",
      align: "center" as const,
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      render: (_, re) => (
        <Button
          type="link"
          onClick={() =>
            navigate(`/home/consultation-details/${re.recordId}/${re.state}`)
          }
        >
          查看
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 返回状态
  const returnState = (state: number) => {
    if (state === 0) {
      return <span className="textBlueColor">填写中</span>;
    } else if (state === 1) {
      return <span className="themeFontColor">待诊断</span>;
    } else {
      return <span className="textAshColor">已诊断</span>;
    }
  };

  // 获取今天日期
  const handleTodayClick = (value: string) => {
    let timer = moment();
    switch (value) {
      case "昨天":
        timer = timer.clone().subtract(1, "day").startOf("day");
        break;
      case "三天内":
        timer = timer.clone().subtract(3, "day").startOf("day");
        break;
      case "七天内":
        timer = timer.clone().subtract(7, "day").startOf("day");
        break;
    }
    const startOfDay = timer.clone().startOf("day");
    const endOfDay = moment().clone().endOf("day");

    const formattedStart = startOfDay.format("YYYY-MM-DD HH:mm:ss");
    const formattedEnd = endOfDay.format("YYYY-MM-DD HH:mm:ss");

    setStartTime(formattedStart);
    setEndTime(formattedEnd);
  };

  const getRecord = (number: number = 0) => {
    ConsultationRecordApi.getRecord(
      state,
      doctorName,
      patientName,
      startTime,
      endTime,
      number ?? pageNumber
    ).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  return (
    <div className="consultation_record">
      <h1>问诊记录</h1>
      <div className="consultation_header">
        <h4>
          诊断状态：
          <Select
            placeholder="请选择状态"
            allowClear
            optionFilterProp="label"
            defaultValue={state}
            onChange={(value) => setState(value)}
            options={[
              {
                value: 0,
                label: "填写中",
              },
              {
                value: 1,
                label: "待诊断",
              },
              {
                value: 2,
                label: "已诊断",
              },
            ]}
          />
        </h4>

        <h4 className="flexBlockCenter">
          医生：
          <Input
            placeholder="请输入医生姓名"
            allowClear
            onChange={(e) => setDoctorName(e.target.value)}
          />
        </h4>

        <h4 className="flexBlockCenter">
          患者：
          <Input
            placeholder="请输入患者姓名"
            allowClear
            onChange={(e) => setPatientName(e.target.value)}
          />
        </h4>

        <h4 className="select_timer">
          选择日期：
          <Radio.Group
            onChange={(e) => {
              handleTodayClick(e.target.value);
            }}
          >
            {["今天", "昨天", "三天内", "七天内"].map(
              (name: string, index: number) => {
                return (
                  <Radio.Button key={index} value={name}>
                    {name}
                  </Radio.Button>
                );
              }
            )}
          </Radio.Group>
          <RangePicker
            showTime={{ format: "HH:mm:ss" }}
            format="YYYY-MM-DD HH:mm:ss"
            value={[
              startTime !== null ? dayjs(startTime) : null,
              endTime !== null ? dayjs(endTime) : null,
            ]}
            onChange={(value, dateString) => {
              setStartTime(dateString[0] === "" ? null : dateString[0]);
              setEndTime(dateString[1] === "" ? null : dateString[1]);
            }}
          />
        </h4>

        <Button type="primary" style={{ marginTop: 20 }} onClick={() => getRecord()}>
          查询
        </Button>
      </div>

      <Table<DataType>
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="recordId"
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number);
            getRecord(number);
          },
        }}
      />
    </div>
  );
};

export default ConsultationRecord;

import {
  Avatar,
  Input,
  Layout,
  Menu,
  MenuProps,
  message,
  Modal,
  Popover,
} from "antd";
import "./home_style.css";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import {
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { routers } from "../../routers/routers";
import recordIcon from "../../images/consultation-record.png";
import doctorIcon from "../../images/doctor.png";
import templateIcon from "../../images/template-type.png";
import questionIcon from "../../images/question-type.png";
import issuesIcon from "../../images/issues-list.png";
import consultationIcon from "../../images/Type-of-consultation.png";
import logo from "../../images/logo.jpg";
import { UserOutlined } from "@ant-design/icons";
import LoginApi from "../../apis/loginApi";

type MenuItem = Required<MenuProps>["items"][number];

interface UserInfo {
  userId: string;
  name: string;
  avatar: string;
  telephone: string;
  data: object;
}

const siderStyle: React.CSSProperties = {
  position: "fixed",
  overflow: "auto",
  paddingTop: 76,
  height: "100vh",
  insetInlineStart: 0,
  width: 200,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
};

const items: MenuItem[] = [
  {
    key: "/home",
    icon: <img style={{ width: 20, height: 20 }} alt="" src={recordIcon} />,
    label: "问诊记录",
  },
  {
    key: "/home/doctor",
    icon: <img style={{ width: 20, height: 20 }} alt="" src={doctorIcon} />,
    label: "医生",
  },
  {
    key: "consultation-template-all",
    label: "问诊模版",
    icon: <img style={{ width: 20, height: 20 }} alt="" src={templateIcon} />,
    children: [
      {
        key: "/home/consultation-template",
        label: "问诊模版",
        icon: (
          <img style={{ width: 20, height: 20 }} alt="" src={templateIcon} />
        ),
      },
      {
        key: "/home/disease-related-issues",
        label: "问题类别",
        icon: (
          <img style={{ width: 20, height: 20 }} alt="" src={questionIcon} />
        ),
      },
      {
        key: "/home/problem-category",
        label: "问题列表",
        icon: <img style={{ width: 20, height: 20 }} alt="" src={issuesIcon} />,
      },
    ],
  },
  {
    key: "/home/consultation-type",
    icon: (
      <img style={{ width: 20, height: 20 }} alt="" src={consultationIcon} />
    ),
    label: "问诊类型",
  },
];

// 旧密码
let oldPassword: string = "";
// 新密码
let password: string = "";

const Home: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageRouter, setPageRouter] = useState<string>("问诊记录");
  // 修改密码弹窗
  const [isModel, setIsModel] = useState<boolean>(false);

  const changeRoute = (routerList: RouteObject[]) => {
    routerList.forEach((route: RouteObject) => {
      if (route.path === location.pathname) {
        setPageRouter(route.path);
        return;
      }

      if (route.children && route.children.length > 0) {
        changeRoute(route.children);
      }
    });
  };

  useEffect(() => {
    if (!sessionStorage.getItem("userInfo")) {
      navigate("/login");
    }

    changeRoute(routers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const setPassWord = () => {
    if (!oldPassword) {
      messageApi.error("请输入旧密码!");
      return;
    }

    if (!password) {
      messageApi.error("请输入新密码!");
      return;
    }

    const accountId = (
      JSON.parse(sessionStorage.getItem("userInfo") ?? "") as UserInfo
    ).userId;
    LoginApi.setPassWord(oldPassword, password, accountId).then(() => {
      oldPassword = "";
      password = "";
      messageApi.success(`修改密码成功`);
      messageApi.loading("请重新登录");
      setIsModel(false);
      setTimeout(() => {
        logOut();
      }, 1000);
    });
  };

  const logOut = () => {
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("password");
    sessionStorage.removeItem("userInfo");
    navigate("/login");
  };

  const UserSet = () => {
    return (
      <div>
        <p style={{ cursor: "default" }} onClick={() => setIsModel(true)}>
          修改密码
        </p>
        <p style={{ cursor: "default" }} onClick={logOut}>
          退出
        </p>
      </div>
    );
  };

  const SetPassWord = () => {
    return (
      <Modal
        open={isModel}
        onOk={setPassWord}
        onCancel={() => {
          oldPassword = "";
          password = "";
          setIsModel(false);
        }}
      >
        <h1>修改密码</h1>
        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            旧密码：
          </div>
          <Input.Password
            style={{ flex: 1 }}
            placeholder="请输入密码"
            onChange={(e) => {
              oldPassword = e.target.value;
            }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            新密码：
          </div>
          <Input.Password
            style={{ flex: 1 }}
            placeholder="请输入密码"
            onChange={(e) => {
              password = e.target.value;
            }}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className="home">
      {contextHolder}
      <SetPassWord />
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <img
            style={{ width: 26, height: 26, borderRadius: 4 }}
            src={logo}
            alt=""
          />
          <span className="title">问诊管理系统</span>
          <div style={{ flex: 1 }} />
          <Popover placement="bottomLeft" content={<UserSet />}>
            <Avatar
              style={{ backgroundColor: "#eee" }}
              icon={<UserOutlined style={{ color: "#000" }} />}
            />
          </Popover>
        </Header>
        <Layout>
          <Sider style={siderStyle}>
            <Menu
              mode="inline"
              selectedKeys={[pageRouter]}
              defaultSelectedKeys={[pageRouter]}
              style={{ height: "100%", borderRight: 0 }}
              items={items}
              onClick={(v) => {
                navigate(v.key);
              }}
            />
          </Sider>
          <Layout style={{ marginInlineStart: 200 }}>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default Home;

import {
  Button,
  Flex,
  Input,
  message,
  Modal,
  Popconfirm,
  Table,
  TableProps,
} from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import DiseaseIssueApi from "../../apis/diseaseIssueApi";
import NewDiseaseCategory from "../../components/NewDiseaseCategory";
import {
  DiseaseCategory,
  DiseaseDataType,
  initCategoryModel,
} from "../../model/DiseaseCategoryModel";
import DiseasePreview from "../../components/DiseasePreview";
import "./disease_relatedIssues_style.css";

let initCategory = JSON.parse(JSON.stringify(initCategoryModel));

let diseaseIndex = 0;

const DiseaseRelatedIssues: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModel, setIsModel] = useState<boolean>(false);
  const [previewIsModel, setPreviewIsModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DiseaseDataType[]>([]);
  // 问题名称
  const [diseaseName, setDiseaseName] = useState<string>('');
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns: TableProps<DiseaseDataType>["columns"] = [
    {
      title: "姓名",
      dataIndex: "typeName",
      key: "typeName",
      align: "center" as const,
      width: 180,
    },
    {
      title: "问题",
      dataIndex: "questions",
      key: "questions",
      align: "center" as const,
      render: (_: unknown, record: DiseaseDataType) =>
        record.questions &&
        record.questions.map((question, index) => (
          <span key={index}>
            {question.question}
            {index + 1 === record.questions.length ? "" : "，"}
          </span>
        )),
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      width: 280,
      render: (_: unknown, record: DiseaseDataType, index) => (
        <div className="themeFontColor">
          <Button
            type="link"
            onClick={() => {
              diseaseIndex = index;
              setPreviewIsModel(true);
            }}
          >
            查看
          </Button>
          |
          <Button
            type="link"
            onClick={() => {
              const newRecord: DiseaseCategory = JSON.parse(
                JSON.stringify(record)
              );
              newRecord.questionIds = record.questions
                ? record.questions.map((item) => item.questionId)
                : [];
              initCategory = { ...newRecord };
              setIsModel(true);
            }}
          >
            编辑
          </Button>
          |
          <Popconfirm
            title="确定要删除该问题吗?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="确定"
            cancelText="取消"
            onConfirm={() => onDelete(record.typeId)}
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PreviewCategory = () => {
    return (
      <Modal
        className="modelPreview"
        open={previewIsModel}
        onCancel={() => setPreviewIsModel(false)}
        footer=""
      >
        <DiseasePreview values={[data[diseaseIndex]]} />
      </Modal>
    );
  };

  const getCategoryList = (number: number = 0) => {
    DiseaseIssueApi.getCategoryList(number ?? pageNumber, diseaseName).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  const onDelete = (typeId: string) => {
    DiseaseIssueApi.deleteCategory(typeId).then(() => {
      getCategoryList();
      messageApi.success(`删除成功`);
    });
  };

  const onSubmit = () => {
    getCategoryList();
  };

  return (
    <div className="DiseaseRelatedIssues pageItem">
      {contextHolder}

      {data.length > 0 && <PreviewCategory />}

      {isModel && (
        <NewDiseaseCategory
          isModel={isModel}
          setIsModel={setIsModel}
          onSubmit={onSubmit}
          initCategory={initCategory}
        />
      )}

      <h1 className="flexSpaceBetween">
        <span>问题类别</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            initCategory = JSON.parse(JSON.stringify(initCategoryModel));
            setIsModel(true);
          }}
        >
          新增
        </Button>
      </h1>

      <Flex vertical={false} justify="space-between">
        <h4 className="flexBlockCenter" style={{ marginBottom: "20px" }}>
          名称：
          <Input
            placeholder="请输入问题名称"
            allowClear
            onChange={(e) => setDiseaseName(e.target.value)}
          />
        </h4>

        <Button type="primary" onClick={() => getCategoryList()}>
          查询
        </Button>
      </Flex>

      <Table<DiseaseDataType>
        loading={loading}
        rowKey="typeId"
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number);
            getCategoryList(number);
          },
        }}
      />
    </div>
  );
};

export default DiseaseRelatedIssues;

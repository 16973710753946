export default class Tools {
  public static QuestionTypeText = (type: number) => {
    switch (type) {
      case 0:
        return "单选";
      case 1:
        return "多选";
      case 2:
        return "文本";
      default:
        return "图片";
    }
  };
}

import { TemplateInfoType } from "../model/ConsultationTemplateModel";
import { QuestionIds } from "../model/DiseaseCategoryModel";
import request from "./request";

export default class ConsultationTemplateApi {
  /**
   * 全部模版类型列表
   */
  public static getTemplateTypeList = () => {
    return request({
      url: "/api/v1/consultation/type/all",
      method: "GET",
    });
  };

  /**
   * 全部问题类型列表
   */
  public static getProblemTypeList = () => {
    return request({
      url: "/api/v1/question/type/all",
      method: "GET",
    });
  };

  /**
   * 问题模版列表
   */
  public static getTemplateList = (
    pageNumber: number,
    name: string,
    typeId: string
  ) => {
    return request({
      url: `/api/v1/consultation/template/paged`,
      method: "GET",
      params: {
        name: name,
        typeId: typeId,
        pageNumber: pageNumber,
        pageSize: 10,
      },
    });
  };

  /**
   * 获取阿里云上传地址
   */
  public static getOssUrl = () => {
    return request({
      url: "/api/v1/aliyun/oss",
      method: "GET",
      params: {
        type: "picture",
      },
    });
  };

  /**
   * 获取阿里云sts上传地址
   */
  public static getOssUrlSts = () => {
    return request({
      url: "/api/v1/aliyun/oss/sts",
      method: "GET",
    });
  };

  /**
   * 获取阿里云地址访问前缀
   */
  public static getOssHost = () => {
    return request({
      url: "/api/v1/aliyun/url/prefix",
      method: "GET",
    });
  };

  /**
   * 新增/修改问诊模版
   */
  public static setTemplate = (info: TemplateInfoType) => {
    return request({
      url: "/api/v1/consultation/template",
      method: "POST",
      data: info,
    });
  };

  /**
   * 新增/修改问诊模版绑定的问诊类型
   */
  public static setProblemType = (id: string, questionTypeIds: QuestionIds[]) => {
    return request({
      url: "/api/v1/consultation/template/question/type",
      method: "POST",
      data: {
        id,
        questionTypeIds,
      },
    });
  };

  /**
   * 获取问诊模版详细信息
   */
  public static getTemplateInfo = (id: string) => {
    return request({
      url: `/api/v1/consultation/template/${id}`,
      method: "GET",
    });
  };

  /**
   * 获取问诊模版的绑定问题类型
   */
  public static getTemplateTypes = (templateId: string) => {
    return request({
      url: `/api/v1/consultation/template/type/number`,
      method: "GET",
      params: { templateId },
    });
  };

  /**
   * 删除模版
   */
  public static deleteTemplate = (templateId: string) => {
    return request({
      url: `/api/v1/consultation/template/${templateId}`,
      method: "DELETE",
    });
  };

  /**
   * 修改模板的分享图片
   */
  public static sharePictureUrl = (templateId: string, sharePictureUrl: string) => {
    return request({
      url: `/api/v1/consultation/template/share/picture`,
      method: "POST",
      data: {
        id: templateId,
        sharePictureUrl
      }
    });
  };

  /**
   * 启用/禁用模版
   */
  public static setTemplateState = (templateId: string, state: boolean) => {
    return request({
      url: `/api/v1/consultation/template/state`,
      method: "POST",
      data: {
        id: templateId,
        state
      }
    });
  };
}

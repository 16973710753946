import { Space, Image, Switch, Button } from "antd";
import "./consultation_details.css";
import { useEffect, useState } from "react";
import ConsultationRecordApi from "../../apis/consultationRecordApi";
import { useParams } from "react-router-dom";
import wordIcon from "../../images/icon-word.png";
import pdfIcon from "../../images/icon-pdf.png";

interface UserInfo {
  name: string;
  gender?: "man" | "women";
  age: number;
  height: string;
  weight: string;
  contactWay: string;
  symptomDescription: string;
  supplementaryMaterials: string;
  typeList: TypeListItem[];
  patientVisible: boolean;
  fourDiagnosis: string;
  dialectical: string;
  analysis: string;
  prescription: string;
}

interface TypeListItem {
  typeId: string;
  typeName: string;
  questions: QuestionsItem[];
}

export interface QuestionsItem {
  questionId: string;
  type: number;
  question: string;
  describe: string;
  required: boolean;
  limitOptionsNumber: number;
  index: number;
  answer: string;
  serious: boolean;
  options: OptionsItem[];
}

interface OptionsItem {
  optionId: string;
  questionOption: string;
  index: number;
  noneStatus: boolean;
  otherStatus: boolean;
}

const ConsultationDetails: React.FC = () => {
  const { userId, state } = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    getRecordDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getRecordDetails = () => {
    userId !== undefined &&
      ConsultationRecordApi.getRecordDetails(userId).then((value) => {
        setUserInfo(value.data);
      });
  };

  const ReturnAttachment = (url: string) => {
    let src = url;
    if (
      url.indexOf(".doc") !== -1 ||
      url.indexOf(".docx") !== -1 ||
      url.indexOf(".docm") !== -1 ||
      url.indexOf(".dotx") !== -1 ||
      url.indexOf(".dotm") !== -1 ||
      url.indexOf(".xml") !== -1
    ) {
      src = wordIcon;
    } else if (url.indexOf(".pdf") !== -1) {
      src = pdfIcon;
    }

    return <Image preview={false} width={60} height={60} src={src} />;
  };

  return (
    <div className="ConsultationDetails textStyle">
      <div className="pageItem">
        <h1>
          <span>问诊详情</span>
          <Button type="link" onClick={() => window.history.back()}>
            返回上一页
          </Button>
        </h1>
        <h3>基本信息</h3>

        <div className="pageItem_block pageItem1_1">
          <div>
            <span className="textAshColor titleOneLEtter">就诊人：</span>
            <span>
              {userInfo
                ? `${userInfo.name}，${
                    userInfo.gender === "man" ? "男" : "女"
                  }，${userInfo.age}岁，${userInfo.height}cm，${
                    userInfo.weight
                  }kg`
                : ""}
            </span>
          </div>
          <div className="flexBlockTop">
            <span className="textAshColor titleOneLEtter">补充：</span>
            <div className="flexBlockCenter">
              {userInfo?.supplementaryMaterials &&
                userInfo.supplementaryMaterials.split(",").map((src, index) => (
                  <a
                    style={{ marginRight: 10 }}
                    key={index}
                    href={src}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ReturnAttachment(src)}
                    <div style={{ textAlign: "center" }}>附件{index + 1}</div>
                  </a>
                ))}
            </div>
          </div>
        </div>

        <div className="pageItem_block pageItem1_1">
          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">主 诉：</span>
            <span>{userInfo?.symptomDescription}</span>
          </div>

          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">类 型 方 式：</span>
            <span>{userInfo?.contactWay}</span>
          </div>
        </div>
      </div>

      {userInfo && userInfo.typeList.length > 0 && (
        <div className="blockItem">
          <div className="flexBlockCenter">
            <h3>症状详情</h3>
            <span
              className="textAshColor"
              style={{ marginLeft: "10px", color: "red" }}
            >
              注意：红色文字是患者更显著的症状
            </span>
          </div>

          <Space
            direction="vertical"
            size={20}
            style={{ width: "100%", marginTop: 20 }}
          >
            {userInfo.typeList &&
              userInfo.typeList.map((item, index) => {
                return (
                  <div key={index}>
                    <h4>{item.typeName}</h4>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.questions &&
                        item.questions.map((questionsItem, key) => {
                          switch (questionsItem.type) {
                            case 3:
                              return (
                                <div
                                  key={key}
                                  style={{ minWidth: "50%", marginTop: 10 }}
                                >
                                  <span className="textAshColor titleOneLEtter">
                                    {questionsItem.question}：
                                  </span>
                                  {questionsItem.answer &&
                                    questionsItem.answer.split(",").map(
                                      (src, num) =>
                                        src !== "" && (
                                          <div
                                            style={{
                                              display: "inline-block",
                                              marginRight: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Image
                                                key={num}
                                                src={src}
                                                width={100}
                                                height={100}
                                              />
                                              <span style={{ marginTop: 5 }}>
                                                {num === 0
                                                  ? "舌苔正面照"
                                                  : "舌底照"}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                    )}
                                </div>
                              );
                            default:
                              return (
                                <div
                                  key={key}
                                  style={{ minWidth: "50%", marginTop: 10 }}
                                >
                                  <span
                                    className="textAshColor titleOneLEtter"
                                    style={{
                                      color: questionsItem.serious
                                        ? "red"
                                        : undefined,
                                    }}
                                  >
                                    {questionsItem.question}：
                                  </span>
                                  <span>{questionsItem.answer}</span>
                                </div>
                              );
                          }
                        })}
                    </div>
                  </div>
                );
              })}
          </Space>
        </div>
      )}

      {state === "2" && (
        <div className="blockItem">
          <div className="flexBlockCenter">
            <h3>医生诊断</h3>
            <span className="textAshColor" style={{ marginLeft: "10px" }}>
              患者可见
            </span>
            <Switch
              disabled
              value={userInfo?.patientVisible}
              style={{ marginLeft: "10px" }}
              defaultChecked
            />
          </div>

          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">四 诊：</span>
            <span>{userInfo?.fourDiagnosis}</span>
          </div>

          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">辩 证：</span>
            <span>{userInfo?.dialectical}</span>
          </div>

          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">分 析：</span>
            <span>{userInfo?.analysis}</span>
          </div>

          <div className="pageItem_block">
            <span className="textAshColor titleOneLEtter">处 方：</span>
            {userInfo &&
              userInfo.prescription &&
              userInfo.prescription
                .split(",")
                .map((src, index) => (
                  <Image
                    style={{ marginRight: 15 }}
                    key={index}
                    width={100}
                    height={100}
                    src={src}
                  />
                ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultationDetails;

import { DiseaseCategory, QuestionIds } from "../model/DiseaseCategoryModel";
import request from "./request";

export default class DiseaseIssueApi {
  /**
   * 问题类型列表
   */
  public static getCategoryList = (pageNumber: number, name: string) => {
    return request({
      url: `/api/v1/question/type/paged`,
      method: "GET",
      params: {
        name: name,
        pageNumber: pageNumber,
        pageSize: 10,
      },
    });
  };

  /**
   * 新增/修改问题类型
   */
  public static setCategory = (category: DiseaseCategory, questionIds: QuestionIds[]) => {
    return request({
      url: `/api/v1/question/type/add`,
      method: "POST",
      data: {
        ...category,
        questionIds
      },
    });
  };

  /**
   * 删除问题类型
   */
  public static deleteCategory = (typeId: string) => {
    return request({
      url: `/api/v1/question/type/${typeId}`,
      method: "DELETE",
    });
  };
}

import { BrowserRouter } from "react-router-dom";
import { RouterView } from "./routers/routers";
import { Suspense } from "react";

function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <RouterView />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

import { Button, Input, Table, TableProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DoctorApi from "../../apis/doctorApi";

interface DataType {
  doctorId: string;
  name: string;
  jobTitle: string;
  skilledIllness: string;
  number: number;
}

const Doctor: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataType[]>([]);
  // 医生名称
  const [doctorName, setDoctorName] = useState<string>('');
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
    },
    {
      title: "职称",
      dataIndex: "jobTitle",
      key: "jobTitle",
      align: "center" as const,
    },
    {
      title: "擅长疾病",
      dataIndex: "skilledIllness",
      key: "skilledIllness",
      align: "center" as const,
    },
    {
      title: "问诊次数",
      dataIndex: "number",
      key: "number",
      align: "center" as const,
    },
    {
      title:"操作",
      key:"操作",
      align: "center" as const,
      render: (_, re) => (
        <Button type="link" onClick={() => navigate("/home/doctor-details/" + re.doctorId)}>
          查看
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getDoctorList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 医生列表
  const getDoctorList = (number: number = 0) => {
    DoctorApi.getDoctorList(number ?? pageNumber, doctorName).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  return (
    <div className="Doctor pageItem">
      <h1>医生</h1>
      <div
        className="flexBlockCenter"
        style={{ marginBottom: "20px", justifyContent: "space-between" }}
      >
        <h4 className="flexBlockCenter">
          医生：
          <Input
            placeholder="请输入医生姓名"
            allowClear
            onChange={(e) => setDoctorName(e.target.value)}
          />
        </h4>

        <Button type="primary" onClick={() => getDoctorList()}>
          查询
        </Button>
      </div>

      <Table<DataType>
        loading={loading}
        rowKey="doctorId"
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number);
            getDoctorList(number);
          },
        }}
      />
    </div>
  );
};

export default Doctor;

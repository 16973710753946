import { message } from "antd";
import axios from "axios";

const request = axios.create({
  baseURL: process.env.REACT_APP_WEB_ENV === 'dev' ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PRO_URL,
  // baseURL: "http://192.168.20.121:9005",
  timeout: 50000,
});

//添加拦截
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {}
);

// 响应拦截
request.interceptors.response.use(
  (res) => {
    if(res.data['code'] === 200) {
      return res.data;
    } else {
      message.error(res.data["message"] ?? "网络错误，请求失败");
      return Promise.reject(res.data);
    }
  },
  (error) => {
    message.error(error.message ?? "网络错误，请求失败");
    if(error.code === 401) {
      localStorage.removeItem("userInfo");
    }
    return Promise.reject(error);
  }
);

export default request;

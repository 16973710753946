import React, { useEffect, useState } from "react";
import { Button, Table, Flex, Modal, Input, message, Popconfirm } from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ConsultationTypeApi from "../../apis/consultationTypeApi";

interface ConsultationTypeItem {
  id: string;
  name: string;
  index: number;
}

// 模版名称
let modelName = "";
// 模版id
let modelId: string | undefined;
// 模版序号
let modelIndex: number = 1;

const ConsultationType: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  // 是否展示新增/编辑模版类型弹窗
  const [isModel, setIsModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ConsultationTypeItem[]>([]);
  // 类型名称
  const [typeName, setTypeName] = useState<string>('');
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns = [
    {
      title: "序列号",
      dataIndex: "index",
      key: "index",
      align: "center" as const,
    },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      render: (_: unknown, record: ConsultationTypeItem) => (
        <div className="themeFontColor">
          <Button
            type="link"
            onClick={() => {
              modelName = record.name;
              modelId = record.id;
              modelIndex = record.index;
              setIsModel(true);
            }}
          >
            编辑
          </Button>
          |
          <Popconfirm
            title="确定要删除该类型吗?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="确定"
            cancelText="取消"
            onConfirm={() => onDelete(record.id)}
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (id: string) => {
    ConsultationTypeApi.deleteType(id).then(() => {
      getTypeList();
      messageApi.success(`删除成功`);
    });
  };

  const NewConsultationType = () => {
    return (
      <Modal open={isModel} onOk={setType} onCancel={() => setIsModel(false)}>
        <h1 style={{ border: 0 }}>新增问诊类型</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>名称：</div>
          <Input
            defaultValue={modelName}
            style={{ flex: 1 }}
            placeholder="请输入问诊类型名称"
            onChange={(e) => (modelName = e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div>序列号：</div>
          <Input
            defaultValue={modelIndex}
            style={{ flex: 1 }}
            placeholder="请输入问诊类型序列号"
            min={1}
            type="number"
            onChange={(e) =>
              (modelIndex = e.target.value ? parseInt(e.target.value) : 1)
            }
          />
        </div>
      </Modal>
    );
  };

  const getTypeList = (number: number = 0) => {
    ConsultationTypeApi.getTypeList(number ?? pageNumber, typeName).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  const setType = () => {
    if (!modelName) {
      messageApi.error("请输入问诊类型名称!");
      return;
    }

    messageApi.loading({
      key: "NewConsultationTypeLoading",
      duration: 0,
      content: "",
    });

    ConsultationTypeApi.setType(modelName, modelId, modelIndex).then(() => {
      getTypeList();
      setIsModel(false);
      messageApi.destroy("NewConsultationTypeLoading");
      messageApi.success(`${modelId ? "修改" : "新增"}问诊类型成功`);
    });
  };
  return (
    <div className="ConsultationType pageItem textStyle">
      <NewConsultationType />

      {contextHolder}

      <h1 className="flexSpaceBetween">
        <span>问诊类型</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            modelName = "";
            modelId = undefined;
            modelIndex = 1;
            setIsModel(true);
          }}
        >
          新增
        </Button>
      </h1>

      <Flex
        vertical={false}
        justify="space-between"
        style={{ marginBottom: "20px" }}
      >
        <h4 className="flexBlockCenter">
          名称：
          <Input
            placeholder="请输入问诊类型名称"
            allowClear
            onChange={(e) => setTypeName(e.target.value)}
          />
        </h4>

        <Button style={{ marginLeft: 20 }} type="primary" onClick={() => getTypeList()}>
          查询
        </Button>
      </Flex>

      <Table<ConsultationTypeItem>
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number);
            getTypeList(number);
          },
        }}
      />
    </div>
  );
};

export default ConsultationType;

import { ProblemOptionItem } from "./ProblemCategoryModel";

export interface DiseaseDataType {
  typeId: string;
  typeName: string;
  range: number;
  index: number;
  questions: QuestionsItem[];
}

export interface QuestionsItem {
  questionId: string;
  question: string;
  type: number;
  describe: string;
  required: boolean;
  options: ProblemOptionItem[];
}

export interface DiseaseCategory {
    typeId: string;
    typeName: string;
    range: number;
    rangeList: number[];
    index: number;
    questionIds: string[];
}

export interface QuestionIds {
  id: string;
  index: number;
}

export const initCategoryModel = {
  typeId: "",
  typeName: "",
  range: 0,
  rangeList: [],
  index: 0,
  questionIds: []
};
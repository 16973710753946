import {
  Button,
  Checkbox,
  Flex,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ProblemList, initProblemModel } from "../model/ProblemCategoryModel";
import NewProblemCategory from "./NewProblemCategory";
import ProblemCategoryApi from "../apis/problemCategoryApi";
import { DiseaseCategory, QuestionIds } from "../model/DiseaseCategoryModel";
import DiseaseIssueApi from "../apis/diseaseIssueApi";
import Tools from "../utils/tools";

const NewDiseaseCategory: React.FC<{
  isModel: boolean;
  setIsModel: (isModel: boolean) => void;
  onSubmit: (category: DiseaseCategory) => void;
  initCategory: DiseaseCategory;
}> = ({ isModel, setIsModel, onSubmit, initCategory }) => {
  const [problemIsModel, setProblemIsModel] = useState<boolean>(false);
  const [problemList, setProblemList] = useState<ProblemList[]>([]);
  const [category, setCategory] = useState<DiseaseCategory>(
    JSON.parse(JSON.stringify(initCategory))
  );

  useEffect(() => {
    getProblemList();
  }, []);

  const getProblemList = () => {
    ProblemCategoryApi.getProblemTemplateList().then((value) => {
      setProblemList(value.data);
    });
  };

  // const handleMouseDown = (id: string) => {
  //   document.addEventListener("mouseup", handleMouseUp);
  //   const element = document.getElementById(id);
  //   if (element) {
  //     const rect = element.getBoundingClientRect();
  //     const position = {
  //       top: rect.top + window.scrollY,
  //       left: rect.left + window.scrollX,
  //     };
  //     console.log("组件位置：", position);
  //   }
  // };

  // const handleMouseUp = (e: MouseEvent) => {
  //   console.log("位置x: " + e.clientX);
  //   console.log("位置y: " + e.clientY);
  //   for (let index = 0; index < category.questionIds.length; index++) {
  //     const element = document.getElementById(
  //       "SelectTagCategory" + category.questionIds[index]
  //     );
  //     if (element) {
  //       const rect = element.getBoundingClientRect();
  //       const position = {
  //         top: rect.top + window.scrollY,
  //         left: rect.left + window.scrollX,
  //       };
  //       console.log("顺序组件位置：", position);
  //     }
  //   }
  //   document.removeEventListener("mouseup", handleMouseUp);
  // };

  const TipText = (type: number, options: string[]) => {
    switch (type) {
      case 2:
        return "文本";
      case 3:
        return "图片";
      default:
        return options ? options.map(
          (item, index) => item + (index + 1 === options.length ? "" : "，")
        ) : "无";
    }
  };

  return (
    <Modal
      open={isModel}
      onOk={() => {
        if (
          category.typeName === "" ||
          category.questionIds.length === 0 ||
          category.rangeList.length === 0
        ) {
          message.error("您还有必填项未填写!");
          return;
        }
        message.loading({
          duration: 0,
          content: "",
          key: "SetProblemCategoryLoading",
        });

        const questionIds: QuestionIds[] = category.questionIds.map(
          (id, index) => ({ id, index })
        );

        DiseaseIssueApi.setCategory(category, questionIds).then(() => {
          onSubmit(category);
          setIsModel(false);
          message.destroy("SetProblemCategoryLoading");
          message.success(
            `${category.typeId !== "" ? "修改" : "新增"}问题类别成功`
          );
        });
      }}
      onCancel={() => setIsModel(false)}
    >
      <div>
        {problemIsModel && (
          <NewProblemCategory
            initProblem={JSON.parse(JSON.stringify(initProblemModel))}
            isModel={problemIsModel}
            setIsModel={setProblemIsModel}
            onSubmit={(problem) => {
              problemList.push({
                questionId: problem.questionId,
                question: problem.question,
                type: problem.type,
                describe: problem.describe,
                options: [],
              });
              setProblemList([...problemList]);
              category.questionIds.push(problem.questionId);
              setCategory({ ...category });
            }}
          />
        )}

        <h1>{category.typeId !== "" ? "修改" : "新增"}问题类别</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            类别名称：
          </div>
          <Input
            defaultValue={category.typeName}
            style={{ flex: 1 }}
            placeholder="请输入类别名称"
            onChange={(e) => (category.typeName = e.target.value)}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            问题：
          </div>
          <Select
            mode="tags"
            style={{ flex: 1 }}
            showSearch
            optionFilterProp="label"
            placeholder="请选择问题模版"
            // tagRender={(props) => (
            //   <Tag
            //     id={"SelectTagCategory" + props.value}
            //     key={props.value}
            //     {...props}
            //     onMouseDown={() =>
            //       handleMouseDown("SelectTagCategory" + props.value)
            //     }
            //   >
            //     {props.label}
            //   </Tag>
            // )}
            value={category.questionIds}
            onChange={(v) => {
              category.questionIds = [...v];
              setCategory({ ...category });
            }}
          >
            {problemList.map((item) => (
              <Select.Option key={item.questionId} value={item.questionId}>
                <Tooltip
                  placement="topLeft"
                  title={TipText(item.type, item.options)}
                >
                  <span style={{ width: "100%", display: "inline-block" }}>
                    {item.question + `（${Tools.QuestionTypeText(item.type)}）`}
                  </span>
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
          <div
            className="medalBefore"
            style={{ width: 100, textAlign: "right" }}
          >
            使用范围：
          </div>
          <Checkbox
            style={{ marginRight: 6 }}
            onClick={() => {
              if (category.rangeList.indexOf(0) === -1) {
                category.rangeList = [0, 1, 2, 3];
              } else {
                category.rangeList = [];
              }
              setCategory({ ...category });
            }}
            checked={category.rangeList && category.rangeList.indexOf(0) !== -1}
          >
            全部
          </Checkbox>
          <Checkbox.Group
            value={category.rangeList}
            options={["男性", "女性", "儿童"].map((item, index) => ({
              label: item,
              value: index + 1,
            }))}
            onChange={(v) => {
              let newRangList = [...v];
              if (newRangList.length === 3) newRangList = [0, 1, 2, 3];
              category.rangeList = [...newRangList];
              setCategory({ ...category });
            }}
          />
        </div>

        <Flex style={{ marginTop: 5 }}>
          <div style={{ width: 80, height: 1 }} />

          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setProblemIsModel(true)}
          >
            添加新问题
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default NewDiseaseCategory;

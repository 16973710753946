import request from "./request";

export default class LoginApi {
  /**
   * 登录
   */
  public static login = (username: string, password: string) => {
    return request({
      url: `/api/v1/user/password/login`,
      method: "POST",
      data: {
        username,
        password,
      },
    });
  };

  /**
   * 修改密码
   */
  public static setPassWord = (oldPassword: string, password: string, accountId: string) => {
    return request({
      url: `/api/v1/user/password`,
      method: "POST",
      data: {
        oldPassword,
        password,
        accountId
      },
    });
  };
}

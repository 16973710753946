import React, { useEffect, useState } from "react";
import { Select, Button, Table, Flex, Input, message, Popconfirm } from "antd";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ProblemCategoryApi from "../../apis/problemCategoryApi";
import Tools from "../../utils/tools";
import NewProblemCategory from "../../components/NewProblemCategory";
import {
  ProblemList,
  initProblemModel,
  Problem,
} from "../../model/ProblemCategoryModel";

let initProblem: Problem = { ...initProblemModel };

const ProblemCategory: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModel, setIsModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ProblemList[]>([]);
  // 问题名称
  const [problemName, setProblemName] = useState<string>('');
  // 问题类型
  const [problemType, setProblemType] = useState<number>();
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns = [
    {
      title: "问题名称",
      dataIndex: "question",
      key: "question",
      align: "center" as const,
    },
    {
      title: "属性",
      dataIndex: "options",
      key: "options",
      align: "center" as const,
      render: (_: unknown, record: ProblemList) =>
        record.options &&
        record.options.map((option, index) => (
          <span key={index}>
            {option}
            {index + 1 === record.options.length ? "" : "，"}
          </span>
        )),
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      align: "center" as const,
      render: (_: unknown, record: ProblemList) => (
        <span>{Tools.QuestionTypeText(record.type)}</span>
      ),
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      render: (_: unknown, record: ProblemList) => (
        <div className="themeFontColor">
          <Button
            type="link"
            onClick={() => {
              ProblemCategoryApi.getProblemDetails(record.questionId).then(
                (value) => {
                  initProblem = JSON.parse(JSON.stringify(value.data));
                  setIsModel(true);
                }
              );
            }}
          >
            编辑
          </Button>
          |
          <Popconfirm
            title="确定要删除该问题吗?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="确定"
            cancelText="取消"
            onConfirm={() => onDelete(record.questionId)}
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getProblemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProblemList = (number: number = 0) => {
    ProblemCategoryApi.getProblemList(
      number ?? pageNumber,
      problemName,
      problemType
    ).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  const onSubmit = (problem: Problem) => {
    getProblemList();
  };

  const onDelete = (questionId: string) => {
    ProblemCategoryApi.deleteProblem(questionId).then(() => {
      getProblemList();
      messageApi.success(`删除成功`);
    });
  };

  return (
    <div className="DiseaseRelatedIssues pageItem textStyle">
      {contextHolder}
      {isModel && (
        <NewProblemCategory
          isModel={isModel}
          setIsModel={setIsModel}
          onSubmit={onSubmit}
          initProblem={initProblem}
        />
      )}

      <h1 className="flexSpaceBetween">
        <span>问题列表</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            initProblem = JSON.parse(JSON.stringify(initProblemModel));
            setIsModel(true);
          }}
        >
          新增
        </Button>
      </h1>

      <Flex vertical={false} style={{ marginBottom: "20px" }}>
        <h4 className="flexBlockCenter" style={{ marginRight: "20px" }}>
          名称：
          <Input
            placeholder="请输入问题名称"
            allowClear
            onChange={(e) => setProblemName(e.target.value)}
          />
        </h4>

        <h4>
          类型：
          <Select
            placeholder="请选择问题类型"
            optionFilterProp="label"
            allowClear
            onSelect={(v) => setProblemType(v)}
            options={[
              {
                value: 0,
                label: "单选",
              },
              {
                value: 1,
                label: "多选",
              },
              {
                value: 2,
                label: "文本",
              },
              {
                value: 3,
                label: "图片",
              },
            ]}
          />
        </h4>

        <div style={{ flex: 1 }} />

        <Button type="primary" onClick={() => getProblemList()}>
          查询
        </Button>
      </Flex>

      <Table<ProblemList>
        loading={loading}
        rowKey="questionId"
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number); 
            getProblemList(number);
          },
        }}
      />
    </div>
  );
};

export default ProblemCategory;

import { Checkbox, Col, Radio, Row, Upload } from "antd";
import { DiseaseDataType, QuestionsItem } from "../model/DiseaseCategoryModel";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const DiseasePreview: React.FC<{ values: DiseaseDataType[] }> = ({
  values,
}) => {
  const [valueIndex, setValueIndex] = useState<number>(0);

  const PreviewItem = (item: QuestionsItem) => {
    if (item.type === 1) {
      return (
        <Checkbox.Group style={{ width: "100%" }}>
          <Row>
            {item.options &&
              item.options.map((option, index) => (
                <Col key={index} span={12} style={{ marginTop: 15 }}>
                  <Checkbox value={index}>{option.questionOption}</Checkbox>
                </Col>
              ))}
          </Row>
        </Checkbox.Group>
      );
    } else if (item.type === 2) {
      return (
        <TextArea
          style={{ marginTop: 15, width: 500 }}
          placeholder="请输入您的回答"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      );
    } else if (item.type === 3) {
      return (
        <div className="flexBlockTop" style={{ marginTop: 15 }}>
          {item.options &&
            item.options.map((option, index) => (
              <div
                key={index}
                className="flexSpaceBetween"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <Upload
                  style={{ marginTop: 15 }}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                >
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </button>
                </Upload>
                <span style={{ marginTop: 5 }}>{option.questionOption}</span>
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <Radio.Group style={{ display: "flex", flexWrap: "wrap" }}>
          {item.options &&
            item.options.map((option, index) => (
              <Radio
                key={index}
                style={{ width: "48%", marginTop: 15 }}
                value={index}
              >
                {option.questionOption}
              </Radio>
            ))}
        </Radio.Group>
      );
    }
  };

  return (
    <div style={{ width: 800 }}>
      <div style={{ width: 800, marginLeft: 60 }}>
        <Radio.Group
          defaultValue={valueIndex}
          options={values && values.map((item, index) => ({
            label: item.typeName,
            value: index,
          }))}
          optionType="button"
          onChange={(e) => setValueIndex(e.target.value)}
        />
      </div>

      <div style={{ paddingLeft: 100 }}>
        {values[valueIndex].questions && values[valueIndex].questions.map((item, index) => {
          return (
            <div key={index} style={{ marginTop: 30 }}>
              <h4>
                <span
                  className={`textAshColor titleOneLEtter ${
                    item.required && "medalBefore"
                  }`}
                  style={{ color: "#000" }}
                >
                  {index + 1}.{item.question}
                </span>
                {item.describe && (
                  <span className="textAshColor" style={{ fontSize: 12 }}>
                    ({item.describe})
                  </span>
                )}
              </h4>
              <div style={{ paddingLeft: "1em" }}>{PreviewItem(item)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DiseasePreview;

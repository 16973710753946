import { Flex, Space, Table, TableProps, Image, Button } from "antd";
import "./doctor_details.css";
import { useEffect, useState } from "react";
import DoctorApi from "../../apis/doctorApi";
import { useNavigate, useParams } from "react-router-dom";

interface DataType {
  recordId: string;
  avatar: string;
  nickName: string;
  name: string;
  gender: "man" | "women";
  age: number;
  symptomDescription: string;
  state: number;
  doctorName: string;
}

interface UserInfo {
  userId: string;
  accountId: string;
  name: string;
  avatar: string;
  telphone: string;
  data: UserInfoData;
  openId: string;
  unionId: string;
  role: string[];
}

interface UserInfoData {
  gender?: "man" | "women";
  realName?: string;
  birthDay?: string;
  jobTitle?: string;
  medicalInstitutions?: string;
  isShowClinic?: string;
  entryYears?: number;
  skilledIllness?: string;
}

// 返回状态
const returnState = (state: number) => {
  if (state === 0) {
    return <span className="textBlueColor">填写中</span>;
  } else if (state === 1) {
    return <span className="themeFontColor">待诊断</span>;
  } else {
    return <span className="textAshColor">已诊断</span>;
  }
};

const DoctorDetails: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataType[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      align: "center" as const,
      render: (_, record) => (
        <Image width={50} height={50} src={record.avatar} />
      ),
    },
    {
      title: "昵称",
      dataIndex: "nickName",
      key: "nickName",
      align: "center" as const,
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
    },
    {
      title: "性别",
      dataIndex: "gender",
      key: "gender",
      align: "center" as const,
      render: (_, record) => (
        <span>{record.gender === "man" ? "男" : "女"}</span>
      ),
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
      align: "center" as const,
    },
    {
      title: "主诉",
      dataIndex: "symptomDescription",
      key: "symptomDescription",
      align: "center" as const,
      width: 500,
    },
    {
      title: "诊断状态",
      dataIndex: "state",
      key: "state",
      align: "center" as const,
      render: (_, record) => returnState(record.state),
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      render: (_, re) => (
        <Button
          type="link"
          onClick={() =>
            navigate(`/home/consultation-details/${re.recordId}/${re.state}`)
          }
        >
          查看
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getDoctorDetails();
    getDoctorRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getDoctorDetails = () => {
    userId &&
      DoctorApi.getDoctorDetails(userId).then((value) => {
        console.log("医生详情");
        setUserInfo(value.data);
      });
  };

  const getDoctorRecord = (number: number = 0) => {
    userId &&
      DoctorApi.getDoctorRecord(number ?? pageNumber, userId).then((value) => {
        setTotal(value.data["total"]);
        setData(value.data["list"]);
        setLoading(false);
      });
  };

  return (
    <div className="DoctorDetails">
      <div className="pageItem">
        <h1>
          <span>医生详情</span>
          <Button type="link" onClick={() => window.history.back()}>
            返回上一页
          </Button>
        </h1>
        <h3>基本信息</h3>

        <Space direction="vertical" size={20} style={{ display: "flex" }}>
          <Flex vertical={false}>
            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">姓名：</span>
              <span>{userInfo?.data?.realName}</span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">性别：</span>
              <span>
                {userInfo
                  ? userInfo.data?.gender === "man"
                    ? "男"
                    : "女"
                  : ""}
              </span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">生日：</span>
              <span>{userInfo?.data?.birthDay}</span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">手机：</span>
              <span>{userInfo?.telphone}</span>
            </div>
          </Flex>

          <Flex vertical={false}>
            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">医疗机构：</span>
              <span>{userInfo?.data?.medicalInstitutions}</span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">职 称：</span>
              <span>{userInfo?.data?.jobTitle}</span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">从业年限：</span>
              <span>{userInfo?.data?.entryYears}年</span>
            </div>

            <div className="pageItem_block">
              <span className="textAshColor titleOneLEtter">擅长疾病：</span>
              <span>{userInfo?.data?.skilledIllness}</span>
            </div>
          </Flex>
        </Space>
      </div>

      <div className="blockItem">
        <h3 style={{ marginBottom: 20 }}>诊断记录</h3>

        <Table<DataType>
          columns={columns}
          loading={loading}
          dataSource={data}
          rowKey="recordId"
          pagination={{
            pageSize: 10,
            total: total,
            showSizeChanger: false,
            onChange: (number) => {
              setPageNumber(number);
              getDoctorRecord(number);
            },
          }}
        />
      </div>
    </div>
  );
};

export default DoctorDetails;

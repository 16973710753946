import request from "./request";

export default class ConsultationRecordApi {
    /**
     * 搜索问诊记录
     */
    public static getRecord = (
        state: number|undefined,
        doctorName: string,
        patientName: string,
        startTime: string|null,
        endTime: string|null,
        pageNumber: number,
    ) => {
        return request({
          url: '/api/v1/consultation/record',
          method: 'GET',
          params: {
            "state": state,
            "doctorName": doctorName,
            "patientName": patientName,
            "startTime": startTime,
            "endTime": endTime,
            "pageNumber": pageNumber,
            "pageSize": 10,
          }
        });
    }

    /**
     * 问诊详情
     */
    public static getRecordDetails = (recordId: string) => {
        return request({
          url: `/api/v1/consultation/${recordId}`,
          method: 'GET',
        });
    }
}
import request from "./request";

export default class DoctorApi {
    /**
     * 搜索医生列表
     */
    public static getDoctorList = (pageNumber: number, name: string) => {
        return request({
          url: `/api/v1/user/doctor`,
          method: 'GET',
          params: {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "name": name
          }
        });
    }

    /**
     * 查询医生信息
     */
    public static getDoctorDetails = (accountId: string) => {
        return request({
          url: `/api/v1/user/info`,
          method: 'GET',
          params: {
            "accountId": accountId,
            "source": "wechat_mini",
          }
        });
    };

    /**
     * 医生问诊记录列表
     */
    public static getDoctorRecord = (
        pageNumber: number,
        doctorId: string
    ) => {
        return request({
          url: '/api/v1/consultation/record',
          method: 'GET',
          params: {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "doctorId": doctorId,
          }
        });
    }
}
import {
  Button,
  Flex,
  Input,
  Table,
  TableProps,
  Image,
  Select,
  Popconfirm,
  message,
  Modal,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import ConsultationTemplateApi from "../../apis/consultationTemplateApi";
import {
  ConsultationTemplateDataType,
  TemplateType,
} from "../../model/ConsultationTemplateModel";
import html2canvas from "html2canvas";
import OSS from "ali-oss";
import shareBg1 from "../../images/share-bg1.png";
import shareBg2 from "../../images/share-bg2.png";
import shareBg3 from "../../images/share-bg3.png";
import shareBg4 from "../../images/share-bg4.png";
import shareBg5 from "../../images/share-bg5.png";

const HandleExportContent: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  padding: "36px 22px 33px",
  width: "345px",
  height: "274px",
  background: `url(${shareBg1}) no-repeat`,
  backgroundSize: "100% 100%",
};

const TitleStyle: React.CSSProperties = {
  position: "relative",
  margin: "0 0 20px",
  padding: 0,
  fontWeight: 800,
  fontSize: 20,
  color: "#000000",
  lineHeight: "23px",
};

const TitleBeforeStyle: React.CSSProperties = {
  position: "absolute",
  top: -3,
  left: -5,
  display: "inline-block",
  content: "",
  width: 14,
  height: 14,
  background: `url(${shareBg5}) no-repeat`,
  backgroundSize: "contain",
};

const ConTentStyle: React.CSSProperties = {
  position: "relative",
  boxSizing: "border-box",
  padding: "9px 24px 24px",
  flex: 1,
  background: `url(${shareBg2}) no-repeat`,
  backgroundSize: "100% 100%",
};

const ConTentAfterStyle: React.CSSProperties = {
  position: "absolute",
  bottom: -3,
  right: -15,
  display: "inline-block",
  content: "",
  width: 49,
  height: 35,
  background: `url(${shareBg4}) no-repeat`,
  backgroundSize: "contain",
};

const TextStyle: React.CSSProperties = {
  display: "inline-block",
  marginTop: 15,
  textAlign: "center",
  fontWeight: 800,
  color: "#a26a00",
  lineHeight: "18px",
  background: `url(${shareBg3}) no-repeat`,
  backgroundSize: "100% 100%",
};

const PreviewText1Style: React.CSSProperties = {
  padding: "5px 0",
  width: 107,
  fontSize: 15,
};

const PreviewText2Style: React.CSSProperties = {
  padding: "3px 0",
  width: 76,
  fontSize: 13,
};

// 当前选中的模版id
let templateId = "";
// 当前选中的模版的问题类型列表
let templateQuestions: string[] = [];

const ConsultationTemplate: React.FC = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(true);
  const [types, setTypes] = useState<TemplateType[]>([]);
  const [data, setData] = useState<ConsultationTemplateDataType[]>([]);
  // 模版名称
  const [name, setName] = useState<string>("");
  // 类型id
  const [typeId, setTypeId] = useState<string>("");
  // 页码
  const [pageNumber, setPageNumber] = useState<number>(1);
  // 数据总数
  const [total, setTotal] = useState<number>(0);
  // 分享图片
  const componentRef = useRef(null);
  // 生产分享图片弹窗
  const [isModel, setIsModel] = useState<boolean>(false);
  // 文件访问前缀
  const [OssUrl, setOssUrl] = useState<string>("");

  const columns: TableProps<ConsultationTemplateDataType>["columns"] = [
    {
      title: "图标",
      dataIndex: "icon",
      key: "icon",
      align: "center" as const,
      render: (_, record) => <Image width={50} height={50} src={record.icon} />,
    },
    {
      title: "名称",
      dataIndex: "templateName",
      key: "templateName",
      align: "center" as const,
    },
    {
      title: "分类",
      dataIndex: "questionTypes",
      key: "questionTypes",
      align: "center" as const,
      width: 500,
      render: (_: unknown, record: ConsultationTemplateDataType) =>
        record.questionTypes &&
        record.questionTypes.map((type, index) => (
          <span key={index}>
            {type}
            {index + 1 === record.questionTypes.length ? "" : "，"}
          </span>
        )),
    },
    {
      title: "简介",
      dataIndex: "introduction",
      key: "introduction",
      align: "center" as const,
    },
    {
      title: "使用次数",
      dataIndex: "number",
      key: "number",
      align: "center" as const,
    },
    {
      title: "序列号",
      dataIndex: "index",
      key: "index",
      align: "center" as const,
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      align: "center" as const,
      render: (_: unknown, record: ConsultationTemplateDataType) => (
        <Button
          type="link"
          onClick={() => getTemplateList()}
          danger={!record.state}
        >
          {record.state ? "已启用" : "已禁用"}
        </Button>
      ),
    },
    {
      title: "操作",
      key: "操作",
      align: "center" as const,
      render: (_: unknown, record: ConsultationTemplateDataType) => (
        <div className="themeFontColor">
          <Button
            type="link"
            onClick={() => {
              templateId = record.id;
              templateQuestions = [...record.questionTypes];
              setIsModel(true);
            }}
          >
            分享
          </Button>
          |
          <Button
            type="link"
            onClick={() =>
              navigate("/home/new-consultation-template/" + record.id)
            }
          >
            编辑
          </Button>
          |
          <Popconfirm
            title="确定要删除该模版吗?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okText="确定"
            cancelText="取消"
            onConfirm={() => onDelete(record.id)}
          >
            <Button type="link">删除</Button>
          </Popconfirm>
          |
          <Popconfirm
            title={`确定${record.state ? "禁用" : "启用"}该模版吗?`}
            okText="确定"
            cancelText="取消"
            onConfirm={() => setTemplateState(record.id, record.state)}
          >
            <Button type="link" danger={record.state}>
              {!record.state ? "启用" : "禁用"}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getTemplateTypeList();
    getTemplateList();
    getOssUrlHost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplateTypeList = () => {
    ConsultationTemplateApi.getTemplateTypeList().then((value) => {
      setTypes(value.data);
    });
  };

  const getTemplateList = (number: number = 0) => {
    ConsultationTemplateApi.getTemplateList(
      number ?? pageNumber,
      name,
      typeId
    ).then((value) => {
      setTotal(value.data["total"]);
      setData(value.data["list"]);
      setLoading(false);
    });
  };

  const onDelete = (templateId: string) => {
    ConsultationTemplateApi.deleteTemplate(templateId).then(() => {
      getTemplateList();
      messageApi.success(`删除成功`);
    });
  };

  const setTemplateState = (templateId: string, state: boolean) => {
    ConsultationTemplateApi.setTemplateState(templateId, !state).then(() => {
      getTemplateList();
      messageApi.success(`${state ? "禁用" : "启用"}成功`);
    });
  };

  // 获取oss访问前缀
  const getOssUrlHost = () => {
    ConsultationTemplateApi.getOssHost().then((value) => {
      setOssUrl(value.data);
    });
  };

  // 生成图片并上传
  const handleExport = () => {
    ConsultationTemplateApi.getOssUrlSts().then((value) => {
      if (componentRef.current) {
        html2canvas(componentRef.current)
          .then((canvas) => {
            canvas.toBlob(async (blob) => {
              if (blob) {
                messageApi.loading({
                  duration: 0,
                  content: "",
                  key: "ConsultationTemplateHandleExportLogin",
                });

                const imageName = `zy:application::consultation/picture/template_${Date.now()}.png`;
                const client = new OSS({
                  endpoint: "https://oss-cn-shenzhen.aliyuncs.com/",
                  region: "oss-cn-shenzhen",
                  accessKeyId: value.data["accessKeyId"],
                  accessKeySecret: value.data["accessKeySecret"],
                  stsToken: value.data["securityToken"],
                  bucket: "zhiyuan-consultation",
                  timeout: 50000,
                  secure: true,
                  refreshSTSToken: async () => {
                    const refreshToken =
                      await ConsultationTemplateApi.getOssUrlSts();
                    return {
                      accessKeyId: refreshToken.data["accessKeyId"],
                      accessKeySecret: refreshToken.data["accessKeySecret"],
                      stsToken: refreshToken.data["securityToken"],
                    };
                  },
                });

                let ossResult = await client.put(imageName, blob);
                messageApi.destroy("ConsultationTemplateHandleExportLogin");
                if (ossResult) {
                  let UploadResult =
                    await ConsultationTemplateApi.sharePictureUrl(
                      templateId,
                      OssUrl + imageName
                    );
                  if (UploadResult) {
                    setIsModel(false);
                    messageApi.success("分享图片创建成功");
                  }
                } else {
                  messageApi.error("生成失败，清重新尝试");
                }
              } else {
                messageApi.error("生成失败，清重新尝试");
              }
            });
          })
          .catch(() => messageApi.error("生成失败，清重新尝试"));
      } else {
        messageApi.error("生成失败，清重新尝试");
      }
    });
  };

  const HandleExport = () => {
    return (
      <Modal
        open={isModel}
        okText="创建"
        onOk={handleExport}
        onCancel={() => setIsModel(false)}
      >
        <h1>创建分享图片</h1>
        <Flex justify="center">
          <div ref={componentRef} style={HandleExportContent}>
            <p style={TitleStyle}>
              请填写以下内容进行快速问诊 <div style={TitleBeforeStyle} />
            </p>
            <div style={ConTentStyle}>
              <Row justify="start">
                {(templateQuestions.length > 9
                  ? templateQuestions.slice(0, 9)
                  : templateQuestions
                ).map((item, index) => (
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    key={index}
                    span={templateQuestions.length >= 7 ? 8 : 12}
                  >
                    <div
                      style={{
                        ...TextStyle,
                        ...(templateQuestions.length >= 7
                          ? PreviewText2Style
                          : PreviewText1Style),
                      }}
                    >
                      {item}
                    </div>
                  </Col>
                ))}
              </Row>
              <div style={ConTentAfterStyle} />
            </div>
          </div>
        </Flex>
      </Modal>
    );
  };

  return (
    <div className="ConsultationTemplate pageItem">
      {contextHolder}
      <HandleExport />
      <h1 className="flexSpaceBetween">
        <span>问诊单模版</span>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate("/home/new-consultation-template")}
        >
          新增
        </Button>
      </h1>

      <Flex vertical={false} style={{ marginBottom: "20px" }}>
        <h4 className="flexBlockCenter">
          问诊类型：
          <Select
            placeholder="请选择问诊类型"
            allowClear
            optionFilterProp="label"
            onChange={(value) => setTypeId(value)}
            options={types.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </h4>

        <h4 className="flexBlockCenter" style={{ marginLeft: "20px" }}>
          名称：
          <Input
            placeholder="请输入问诊模版名称"
            allowClear
            onChange={(e) => setName(e.target.value)}
          />
        </h4>

        <div style={{ flex: 1 }} />

        <Button type="primary" onClick={() => getTemplateList()}>
          查询
        </Button>
      </Flex>

      <Table<ConsultationTemplateDataType>
        columns={columns}
        loading={loading}
        dataSource={data}
        rowKey="id"
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: (number) => {
            setPageNumber(number);
            getTemplateList(number);
          },
        }}
      />
    </div>
  );
};

export default ConsultationTemplate;

import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Input, Image, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login_style.css";
import { useEffect, useState } from "react";
import loginBg from "../../images/loginBg.png";
import LoginApi from "../../apis/loginApi";

let userName = "";
let password = "";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  // 是否记住账号密码自动登录
  let isAutoLogin = true;

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.defaultPrevented) return;
      if (e.keyCode === 13) login(userName, password);
    });

    const localName = sessionStorage.getItem("userName");
    const localPassword = sessionStorage.getItem("password");

    if (localName && localPassword) {
      messageApi.loading({content: "自动登录中", key: "loginLoading"});
      userName = localName;
      password = localPassword;
      login(localName, localPassword);
    }

    return () => {
      document.removeEventListener("keydown", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (name: string, password: string) => {
    setLoading(true);
    LoginApi.login(name, password)
      .then((value) => {
        sessionStorage.setItem("userInfo", JSON.stringify(value.data));
        if (isAutoLogin) {
          sessionStorage.setItem("userName", name);
          sessionStorage.setItem("password", password);
        } else {
          sessionStorage.removeItem("userName");
          sessionStorage.removeItem("password");
        }
        setTimeout(() => {
          navigate("/home");
          messageApi.destroy("loginLoading");
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          messageApi.destroy("loginLoading");
          setLoading(false);
        }, 300);
      });
  };

  return (
    <div className="login">
      {contextHolder}
      <Image height={"100vh"} preview={false} src={loginBg} />
      <div style={{ flex: 1 }}>
        <div className="content">
          <p>账号登录</p>
          <Input
            className="user"
            size="large"
            placeholder="请输入您的账号"
            defaultValue={userName}
            prefix={<UserOutlined />}
            onChange={(e) => {
              userName = e.target.value;
            }}
          />
          <Input.Password
            className="password"
            size="large"
            placeholder="请输入密码"
            defaultValue={password}
            prefix={<LockOutlined />}
            onChange={(e) => {
              password = e.target.value;
            }}
          />
          <Checkbox
            defaultChecked={isAutoLogin}
            className="autoLogin"
            onChange={(e) => (isAutoLogin = e.target.checked)}
          >
            下次自动登录
          </Checkbox>
          <Button
            className="loginBtn"
            type="primary"
            loading={loading}
            onClick={() => {
              if (userName === "") {
                messageApi.error("请输入您的账号");
                return;
              }
              if (password === "") {
                messageApi.error("请输入密码");
                return;
              }
              login(userName, password);
            }}
          >
            登录
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;

import {
  Button,
  Card,
  Flex,
  Input,
  message,
  Select,
  Space,
  Steps,
  Upload,
  UploadProps,
  Image,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./new_consultation_template.css";
import NewDiseaseCategory from "../../components/NewDiseaseCategory";
import React from "react";
import ConsultationTemplateApi from "../../apis/consultationTemplateApi";
import {
  OSSDataType,
  TemplateInfoType,
  TemplateProblemType,
  TemplateType,
  TemplateTypes,
} from "../../model/ConsultationTemplateModel";
import {
  initCategoryModel,
  QuestionIds,
} from "../../model/DiseaseCategoryModel";
import DiseasePreview from "../../components/DiseasePreview";
import { useParams } from "react-router-dom";

// 选中的问题类型
let selectProblems: string[] = [];

const NewConsultationTemplate: React.FC = () => {
  const { templateId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  // 当前步骤
  const [step, setStep] = useState<number>(0);
  // 模版信息
  const [templateInfo, setTemplateInfo] = useState<TemplateInfoType>({
    id: "",
    templateName: "",
    key: "",
    index: 0,
    icon: "",
    introduction: "",
    typeId: "",
    typeList: [],
  });
  // 文件访问前缀
  const [OssUrl, setOssUrl] = useState<string>("");
  // 图片上传
  const [OSSData, setOSSData] = useState<OSSDataType>();
  // 上传后的图片地址
  const [imgUrl, setImgUrl] = useState<string>("");
  // 问诊类型列表
  const [typeList, setTypeList] = useState<TemplateType[]>([]);
  // 问题类型列表
  const [typeProblemList, setTypeProblemList] = useState<TemplateProblemType[]>(
    []
  );
  // 新增问题类型弹窗
  const [isModel, setIsModel] = useState<boolean>(false);

  useEffect(() => {
    if (templateId) getTemplateInfo(templateId);
    getTemplateTypeList();
    getOssUrl();
    getProblemTypeList();

    return () => {
      selectProblems = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取问诊模版详情
  const getTemplateInfo = (templateId: string) => {
    ConsultationTemplateApi.getTemplateInfo(templateId)
      .then((value) => {
        setImgUrl(value.data["icon"]);
        setTemplateInfo(value.data);
      })
      .finally(() => messageApi.destroy("NewConsultationTemplateLoading2"));

    ConsultationTemplateApi.getTemplateTypes(templateId).then((value) => {
      const list: TemplateTypes[] = value.data;
      selectProblems = list.map((item) => item.id);
    });
  };

  // 查询所有问诊类型
  const getTemplateTypeList = () => {
    ConsultationTemplateApi.getTemplateTypeList().then((value) => {
      setTypeList(value.data);
    });
  };

  // 获取所有的问题类别
  const getProblemTypeList = () => {
    ConsultationTemplateApi.getProblemTypeList().then((value) => {
      setTypeProblemList(value.data);
    });
  };

  // 获取图片上传地址
  const getOssUrl = () => {
    ConsultationTemplateApi.getOssUrl().then((value) => {
      const newOssData: OSSDataType = value.data;
      newOssData.dir += "/";
      setOSSData(newOssData);
    });

    ConsultationTemplateApi.getOssHost().then((value) => {
      setOssUrl(value.data);
    });
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const StepItem = () => {
    switch (step) {
      case 0:
        return <FoundationInfo />;

      case 1:
        return <DiseaseInfo />;

      default:
        return <Preview />;
    }
  };

  const getExtraData: UploadProps["data"] = (file) => ({
    key: file.url,
    OSSAccessKeyId: OSSData?.keyId,
    policy: OSSData?.policy,
    Signature: OSSData?.signature,
  });

  const beforeUpload: UploadProps["beforeUpload"] = async (file) => {
    if (!OSSData) {
      messageApi.error("上传失败，请重新尝试!");
      return false;
    }

    const expire = Number(OSSData.expire) * 1000;

    if (expire < Date.now()) {
      await getOssUrl();
    }

    const suffix = file.name.slice(file.name.lastIndexOf("."));
    const filename = Date.now() + suffix;
    // @ts-ignore
    file.url = OSSData.dir + filename;

    return file;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList }) => {
    if (fileList.length && fileList[0].status === "done") {
      setImgUrl(OssUrl + fileList[0].url);
      templateInfo.icon = OssUrl + fileList[0].url;
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    action: OSSData?.host,
    maxCount: 1,
    data: getExtraData,
    onChange: handleChange,
    beforeUpload,
    showUploadList: false,
    listType: "picture-card",
  };

  // 第一步
  const FoundationInfo = () => {
    return (
      <div style={{ marginTop: "35px", paddingLeft: "80px" }}>
        <Space direction="vertical" size={20} style={{ display: "flex" }}>
          <h4 className="medalBefore">
            模版名称：
            <Input
              style={{ width: "600px" }}
              placeholder="请输入模版名称"
              defaultValue={templateInfo.templateName}
              onChange={(e) => (templateInfo.templateName = e.target.value)}
            />
          </h4>

          <h4 className="medalBefore">
            模版简介：
            <Input
              style={{ width: "600px" }}
              placeholder="请输入模版简介"
              defaultValue={templateInfo.introduction}
              onChange={(e) => (templateInfo.introduction = e.target.value)}
            />
          </h4>

          <h4 className="medalBefore">
            问诊类型：
            {templateInfo.typeId ? (
              <Select
                defaultValue={templateInfo.typeId}
                style={{ width: "600px" }}
                showSearch
                placeholder="请选择问诊类型"
                optionFilterProp="label"
                onSelect={(value) => (templateInfo.typeId = value)}
                options={typeList.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            ) : (
              <Select
                style={{ width: "600px" }}
                showSearch
                placeholder="请选择问诊类型"
                optionFilterProp="label"
                onSelect={(value) => (templateInfo.typeId = value)}
                options={typeList.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            )}
          </h4>

          <h4 className="medalBefore">
            序列号：
            <Input
              style={{ width: "600px" }}
              defaultValue={templateInfo.index}
              placeholder="请输入问诊类型序列号"
              min={0}
              type="number"
              onChange={(e) => {templateInfo.index = e.target.value ? parseInt(e.target.value) : 0}}
            />
          </h4>

          <h4 className="medalBefore" style={{ display: "flex" }}>
            上传图标：
            <Upload {...uploadProps}>
              {imgUrl ? (
                <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </h4>

          <Button
            style={{ marginLeft: "80px", marginTop: "10px" }}
            type="primary"
            onClick={() => {
              if (
                !templateInfo.templateName ||
                !templateInfo.introduction ||
                !templateInfo.typeId ||
                !imgUrl
              ) {
                messageApi.error("请填写完成当前信息后进入下一步");
                return;
              }

              messageApi.loading({
                key: "NewConsultationTemplateLoading1",
                duration: 0,
                content: "",
              });

              ConsultationTemplateApi.setTemplate(templateInfo).then(
                (value) => {
                  templateInfo.id = value.data;
                  messageApi.destroy("NewConsultationTemplateLoading1");
                  setStep(1);
                }
              );
            }}
          >
            下一步
          </Button>
        </Space>
      </div>
    );
  };

  // 第二步
  const DiseaseInfo = () => {
    return (
      <div style={{ marginTop: "35px", paddingLeft: "100px" }}>
        <Space direction="vertical" size={20} style={{ display: "flex" }}>
          <h4>
            问题类别：
            <Select
              mode="tags"
              style={{ width: "600px" }}
              maxCount={10}
              showSearch
              placeholder="请选择问题类别"
              optionFilterProp="label"
              defaultValue={selectProblems}
              onChange={(v) => (selectProblems = [...v])}
            >
              {typeProblemList.map((item) => (
                <Select.Option key={item.typeId} value={item.typeId}>
                  <Tooltip
                    placement="topLeft"
                    title={
                      item.questions
                        ? item.questions.map(
                            (question, index) =>
                              question.question +
                              (index + 1 === item.questions.length ? "" : "，")
                          )
                        : "无"
                    }
                  >
                    <span style={{ width: "100%", display: "inline-block" }}>
                      {item.typeName}
                    </span>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          </h4>

          <Button
            type="default"
            icon={<PlusOutlined />}
            onClick={() => setIsModel(true)}
          >
            添加新类别
          </Button>

          <Flex vertical={false} justify="center" style={{ width: "100%" }}>
            <Button type="default" onClick={() => setStep(0)}>
              上一步
            </Button>

            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              onClick={() => {
                if (!selectProblems.length) {
                  messageApi.error("请选择问题模版!");
                  return;
                }

                messageApi.loading({
                  key: "NewConsultationTemplateLoading2",
                  duration: 0,
                  content: "",
                });

                const newSelectProblems: QuestionIds[] = selectProblems.map(
                  (id, index) => ({ id, index })
                );

                ConsultationTemplateApi.setProblemType(
                  templateInfo.id,
                  newSelectProblems
                )
                  .then(() => {
                    ConsultationTemplateApi.getTemplateInfo(templateInfo.id)
                      .then((value) => {
                        templateInfo.typeList = [...value.data["typeList"]];
                        setTemplateInfo({ ...templateInfo });
                        setStep(2);
                      })
                      .finally(() =>
                        messageApi.destroy("NewConsultationTemplateLoading2")
                      );
                  })
                  .catch((e) => {
                    messageApi.destroy("NewConsultationTemplateLoading2");
                  });
              }}
            >
              下一步
            </Button>
          </Flex>
        </Space>

        <NewDiseaseCategory
          isModel={isModel}
          setIsModel={setIsModel}
          onSubmit={(value) => {
            selectProblems.push(value.typeId);
            typeProblemList.push({
              typeId: value.typeId,
              typeName: value.typeName,
              rangeList: [...value.rangeList],
              questions: [],
            });
            setTypeProblemList([...typeProblemList]);
          }}
          initCategory={JSON.parse(JSON.stringify(initCategoryModel))}
        />
      </div>
    );
  };

  // 第三步
  const Preview = () => {
    return (
      <Flex
        vertical={true}
        align="center"
        style={{ width: 800, marginTop: "35px" }}
      >
        <h1 style={{ width: 800, marginBottom: "10px" }}>模版封面预览</h1>
        <Card
          hoverable
          style={{ width: 166 }}
          cover={<Image width={166} height={120} src={templateInfo.icon} />}
        >
          <Space direction="vertical" size={0}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="cardTitle">{templateInfo.templateName}</span>{" "}
              <span className="cardSubTitle">
                {typeList.find((item) => item.id === templateInfo.typeId)?.name}
              </span>
            </div>
            <div className="cardInfo">{templateInfo.introduction}</div>
            <div className="cardDescribe">
              {templateInfo.typeList.map((item, index) => (
                <span key={index} style={{ marginLeft: index === 0 ? 0 : 8 }}>
                  {item.typeName}
                </span>
              ))}
            </div>
          </Space>
        </Card>

        <h1 style={{ width: 800, margin: "20px 0 10px" }}>模版内容预览</h1>
        {templateInfo.typeList.length && (
          <DiseasePreview values={templateInfo.typeList} />
        )}

        <Space style={{ marginTop: "50px" }}>
          <Button type="default" onClick={() => setStep(1)}>
            上一步
          </Button>

          <Button type="primary" onClick={() => window.history.back()}>
            完成
          </Button>
        </Space>
      </Flex>
    );
  };

  return (
    <div className="NewConsultationTemplate pageItem textStyle">
      {contextHolder}
      <h1>
        <span>模版{templateId ? "修改" : "新增"} </span>
        <Button type="link" onClick={() => window.history.back()}>
          返回上一页
        </Button>
      </h1>
      <Flex vertical={true} align="center">
        <Steps
          style={{ width: "600px" }}
          current={step}
          items={[
            {
              title: "基础信息",
            },
            {
              title: "病症信息",
            },
            {
              title: "完成",
            },
          ]}
        />

        {StepItem()}
      </Flex>
    </div>
  );
};

export default NewConsultationTemplate;

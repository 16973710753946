import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import Login from "../views/Login";
import Home from "../views/Home";
import ConsultationRecord from "../views/ConsultationRecord";
import ConsultationDetails from "../views/ConsultationDetails";
import NewConsultationTemplate from "../views/NewConsultationTemplate";
import ConsultationTemplate from "../views/ConsultationTemplate";
import ConsultationType from "../views/ConsultationType";
import Doctor from "../views/Doctor";
import DoctorDetails from "../views/DoctorDetails";
import ProblemCategory from "../views/ProblemCategory";
import DiseaseRelatedIssues from "../views/DiseaseRelatedIssues";

const routers: RouteObject[] = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
    children: [
      {
        index: true,
        element: <ConsultationRecord />,
      },
      {
        path: "/home/consultation-details/:userId/:state",
        element: <ConsultationDetails />,
      },
      {
        path: "/home/consultation-template",
        element: <ConsultationTemplate />,
      },
      {
        path: "/home/new-consultation-template",
        element: <NewConsultationTemplate />,
      },
      {
        path: "/home/new-consultation-template/:templateId",
        element: <NewConsultationTemplate />,
      },
      {
        path: "/home/consultation-type",
        element: <ConsultationType />,
      },
      {
        path: "/home/doctor",
        element: <Doctor />,
      },
      {
        path: "/home/doctor-details/:userId",
        element: <DoctorDetails />,
      },
      {
        path: "/home/problem-category",
        element: <ProblemCategory />,
      },
      {
        path: "/home/disease-related-issues",
        element: <DiseaseRelatedIssues />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
];

const RouterView = () => {
  const elem = useRoutes(routers);
  return elem;
};

export { routers, RouterView };

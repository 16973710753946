import request from "./request";

export default class ConsultationTypeApi {
  /**
   * 模版类型列表
   */
  public static getTypeList = (pageNumber: number, typeName: string) => {
    return request({
      url: "/api/v1/consultation/type",
      method: "GET",
      params: {
        pageNumber: pageNumber,
        pageSize: 10,
        typeName: typeName,
      },
    });
  };

  /**
   * 新增/修改模版类型
   */
  public static setType = (
    typeName: string,
    id: string | undefined,
    index: number | undefined
  ) => {
    return request({
      url: "/api/v1/consultation/type",
      method: "POST",
      data: {
        name: typeName,
        id: id,
        index: index,
      },
    });
  };

  /**
   * 删除模版类型
   */
  public static deleteType = (id: string) => {
    return request({
      url: `/api/v1/consultation/type/${id}`,
      method: "DELETE",
    });
  };
}

export interface Problem {
  questionId: string;
  type: 0 | 1 | 2 | 3;
  question: string;
  describe: string;
  required: boolean;
  limitOptionsNumber: number;
  index: number;
  options: ProblemOptionItem[];
  limitMaxAge?: number;
  limitMinAge?: number;
}

export interface ProblemOptionItem {
  id: number;
  questionOption: string;
  noneStatus: boolean;
  otherStatus: boolean;
  index: number;
}

export interface ProblemList {
  questionId: string;
  type: number;
  question: string;
  describe: string;
  options: string[];
}


export const initProblemModel: Problem = {
  questionId: "",
  type: 0,
  question: "",
  describe: "",
  required: false,
  limitOptionsNumber: 3,
  index: 0,
  options: [
    {
      id: 1,
      questionOption: "",
      noneStatus: false,
      otherStatus: false,
      index: 0,
    },
  ],
};
